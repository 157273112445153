import React, { useContext } from 'react';
import ZIndexManager from 'components/ZIndexManager';
import { Button } from 'components/buttons';
import CollapsableText from 'components/CollapsableText/CollapsableText';
import { routes } from 'utils/routes';
import { MembershipStatus, MembershipType, PlanTiers } from 'api/types';
import { useTranslation } from 'react-i18next';
import useInBrowser from 'hooks/useInBrowser';
import { userCameFromSearch } from 'utils/integrations/sbjs';
import { RegwallContext } from 'pages/search/useRegwall';
import Link from 'components/Link';
import PremiumBadge from 'components/Badges/PremiumBadge';
import { IconAirbnb, IconFacebook2, IconLinkedIn } from 'components/Icon';
import ExperimentalFeature, { experiments, UTM_SOURCES } from 'containers/ExperimentalFeature';
import { FavouriteButton } from '@ths/design-system';
import SitterSocialLink from '../SitterSocialLink';
import { airbnbLink, linkedInLink, instagramLink, facebookLink, hasAnySocial } from './helpers';

import {
    ButtonStyled,
    InfoBoxStyled,
    LocationStyled,
    ReviewIconStyled,
    LookIconStyled,
    RatingStyled,
    ClickableReviewsStyled,
    VerificationsSectionStyled,
    VerificationsBulletsStyled,
    VerificationCheckIconStyled,
    TitleStyled,
    CTAContainerStyled,
    CTALinkStyled,
    CTATextLargeTabletStyled,
    CTATextStyled,
    DescriptionTextStyled,
    SocialLinksDesktopStyled,
    PremiumBadgeWrapper,
    LoadingBox,
} from './SitterInfoBox.style';

const GetPlanLink = ({ t }) => (
    <CTALinkStyled
        data-testid="get-plan-link"
        variant={Link.Variant.Button.CTA}
        to={routes.accounts.explorePlans()}
    >
        <CTATextStyled>{t('pages_profileDetail_sitterInfoBox_getPlan')}</CTATextStyled>
        <CTATextLargeTabletStyled>
            {t('pages_profileDetail_sitterInfoBox_getPlan_short')}
        </CTATextLargeTabletStyled>
    </CTALinkStyled>
);

const FreeTrialLink = ({ t }) => (
    <CTALinkStyled
        data-testid="get-plan-link"
        variant={Link.Variant.Button.CTA}
        to={routes.accounts.explorePlans()}
    >
        <CTATextStyled>{t('components_cta_start_free_trial')}</CTATextStyled>
        <CTATextLargeTabletStyled>{t('components_cta_start_free_trial')}</CTATextLargeTabletStyled>
    </CTALinkStyled>
);

const CheckoutLink = ({ t }) => (
    <CTALinkStyled
        data-testid="checkout-link-variation"
        variant={Link.Variant.Button.CTA}
        to={routes.accounts.explorePlans()}
    >
        <CTATextStyled>{t('pages_profileDetail_sitterInfoBox_checkout')}</CTATextStyled>
        <CTATextLargeTabletStyled>
            {t('pages_profileDetail_sitterInfoBox_checkout_short')}
        </CTATextLargeTabletStyled>
    </CTALinkStyled>
);

const RenewPlanLink = ({ t }) => (
    <CTALinkStyled
        data-testid="renew-plan-link"
        variant={Link.Variant.Button.CTA}
        to={routes.accounts.explorePlans()}
    >
        {t('pages_profileDetail_sitterInfoBox_renewPlan')}
    </CTALinkStyled>
);

const UpgradePlanLink = ({ t }) => (
    <CTALinkStyled
        data-testid="upgrade-plan-link"
        variant={Link.Variant.Button.CTA}
        to={`${routes.accounts.signUp.upgrade()}?membershipType=combined`}
    >
        {t('pages_profileDetail_sitterInfoBox_upgradePlan')}
    </CTALinkStyled>
);

const InviteLink = ({ t, sitterUserId, sitterProfileId }) => (
    <CTALinkStyled
        data-testid="invite-link"
        variant={Link.Variant.Button.PRIMARY}
        to={routes.search.profileInvitation({
            contactId: sitterUserId,
            profileId: sitterProfileId,
        })}
    >
        {t('pages_profileDetail_sitterInfoBox_invite')}
    </CTALinkStyled>
);

const CompleteListingLink = ({ t, ownerListingId }) =>
    ownerListingId ? (
        <CTALinkStyled
            data-testid="complete-listing-link"
            variant={Link.Variant.Button.PRIMARY}
            to={routes.user.listing.edit.hub({
                listingId: ownerListingId,
            })}
        >
            {t('pages_profileDetail_sitterInfoBox_completeListing')}
        </CTALinkStyled>
    ) : null;

const OpenRegwallCta = ({ children }) => {
    const { open } = useContext(RegwallContext);

    return (
        <ButtonStyled variant={Button.Variant.CTA} onClick={open}>
            {children}
        </ButtonStyled>
    );
};

const InviteCTA = ({
    t,
    isLoggedIn,
    membershipStatus,
    membershipType,
    hasApprovedListing,
    sitterUserId,
    sitterProfileId,
    ownerListingId,
}) => {
    if (!isLoggedIn) {
        if (userCameFromSearch()) {
            return (
                <ExperimentalFeature
                    experiment={experiments.FREE_TRIAL_OWNER_V2}
                    excludeUTMSources={[UTM_SOURCES.CJ, UTM_SOURCES.IMPACT]}
                    control={
                        <OpenRegwallCta>
                            {t('pages_profileDetail_sitterInfoBox_openRegwall')}
                        </OpenRegwallCta>
                    }
                    variation1={
                        <OpenRegwallCta>{t('components_cta_start_free_trial')}</OpenRegwallCta>
                    }
                />
            );
        }
        return (
            <ExperimentalFeature
                experiment={experiments.FREE_TRIAL_OWNER_V2}
                excludeUTMSources={[UTM_SOURCES.CJ, UTM_SOURCES.IMPACT]}
                control={<GetPlanLink t={t} />}
                variation1={<FreeTrialLink t={t} />}
            />
        );
    }

    if (membershipStatus === MembershipStatus.PARTIAL) {
        return (
            <>
                {membershipType === MembershipType.OWNER ? (
                    <ExperimentalFeature
                        experiment={experiments.FREE_TRIAL_OWNER_V2}
                        excludeUTMSources={[UTM_SOURCES.CJ, UTM_SOURCES.IMPACT]}
                        control={<CheckoutLink t={t} />}
                        variation1={<FreeTrialLink t={t} />}
                    />
                ) : (
                    <CheckoutLink t={t} />
                )}
            </>
        );
    }
    if (membershipStatus === MembershipStatus.EXPIRED) {
        return <RenewPlanLink t={t} />;
    }

    if (membershipStatus === MembershipStatus.MEMBER) {
        // Sitter Members must upgrade to invite sitters
        if (membershipType === MembershipType.SITTER) {
            return <UpgradePlanLink t={t} />;
        }
        /**
         * Owner Members and Combined Members can invite sitters,
         * but only if they have a completed (approved) listing
         */
        if (membershipType === MembershipType.OWNER || membershipType === MembershipType.COMBINED) {
            if (hasApprovedListing) {
                return (
                    <InviteLink
                        t={t}
                        sitterUserId={sitterUserId}
                        sitterProfileId={sitterProfileId}
                    />
                );
            }
            return <CompleteListingLink t={t} ownerListingId={ownerListingId} />;
        }
    }

    /**
     * In theory any user should fall into the previous categories
     * If we get to this point then something in their account is broken and we
     * need to handle that case appropriately.
     */
    return null;
};

const SitterInfoBox = ({
    sitterName,
    partnerName,
    title,
    location,
    reviewsCount,
    referencesCount,
    membershipType,
    membershipStatus,
    hasApprovedListing,
    isLoggedIn,
    sitterProfileId,
    sitterUserId,
    ownerListingId,
    viewerIsMember,
    socialVerifications,
    sitterMembershipTier,
    isPreview,
    completedVerifications,
    profileHasLoaded,
    islookingInYourArea,
    onClickReviews,
    isFavourited,
    isFavouriteButtonLoading,
    onClickFavourite,
}) => {
    const inBrowser = useInBrowser();
    const { t } = useTranslation();
    const sitterNames = sitterName && partnerName ? `${sitterName} & ${partnerName}` : sitterName;

    const isSitterPremium = sitterMembershipTier === PlanTiers.PREMIUM;

    const {
        hasVerifiedCriminalBackgroundCheck,
        hasVerifiedEmail,
        hasVerifiedIdCheck,
        hasVerifiedPhone,
    } = completedVerifications;

    return (
        <InfoBoxStyled>
            <TitleStyled>{sitterNames}</TitleStyled>
            {profileHasLoaded || isPreview ? (
                <CollapsableText
                    method="maxCharacters"
                    maxCharacters={90}
                    text={title}
                    openButtonText={t('pages_profileDetail_showMoreText')}
                    closeButtonText={t('pages_profileDetail_showLessText')}
                >
                    {({ text }) => (
                        <DescriptionTextStyled data-testid="SitterInfoBox_description">
                            {text}
                        </DescriptionTextStyled>
                    )}
                </CollapsableText>
            ) : (
                <>
                    <LoadingBox data-testid="SitterInfoBox_loadingBox01" />
                    <LoadingBox data-testid="SitterInfoBox_loadingBox02" />
                    <LoadingBox data-testid="SitterInfoBox_loadingBox03" />
                </>
            )}

            {!islookingInYourArea && <LocationStyled>{location}</LocationStyled>}
            {islookingInYourArea && (
                <RatingStyled>
                    <LookIconStyled />
                    {t('components_profileCard_looking_in_your_area')}
                </RatingStyled>
            )}
            <VerificationsSectionStyled>
                {reviewsCount > 0 ? (
                    <ClickableReviewsStyled onClick={onClickReviews}>
                        <ReviewIconStyled />
                        {reviewsCount}{' '}
                        {t('pages_profileDetail_sitterInfoBox_review', {
                            count: reviewsCount,
                        })}
                    </ClickableReviewsStyled>
                ) : null}

                {hasVerifiedEmail && (
                    <VerificationsBulletsStyled
                        isSitterPremium={isSitterPremium}
                        data-testid="verifiedEmail"
                    >
                        <VerificationCheckIconStyled palette="primaryRegular" />
                        {t('pages_profileDetail_sitterInfoBox_email')}
                    </VerificationsBulletsStyled>
                )}

                {hasVerifiedPhone && (
                    <VerificationsBulletsStyled
                        isSitterPremium={isSitterPremium}
                        data-testid="verifiedPhone"
                    >
                        <VerificationCheckIconStyled palette="primaryRegular" />
                        {t('pages_profileDetail_sitterInfoBox_phone')}
                    </VerificationsBulletsStyled>
                )}

                {hasVerifiedIdCheck && (
                    <VerificationsBulletsStyled
                        isSitterPremium={isSitterPremium}
                        data-testid="verifiedId"
                    >
                        <VerificationCheckIconStyled palette="primaryRegular" />
                        {t('pages_profileDetail_sitterInfoBox_id')}
                    </VerificationsBulletsStyled>
                )}

                {referencesCount > 0 ? (
                    <VerificationsBulletsStyled isSitterPremium={isSitterPremium}>
                        <VerificationCheckIconStyled palette="primaryRegular" />
                        {t('pages_profileDetail_sitterInfoBox_reference', {
                            count: referencesCount,
                        })}
                    </VerificationsBulletsStyled>
                ) : null}

                {/*  completed a criminal background check  */}
                {hasVerifiedCriminalBackgroundCheck ? (
                    <VerificationsBulletsStyled
                        isSitterPremium={isSitterPremium}
                        data-testid="verifiedCBC"
                    >
                        <VerificationCheckIconStyled palette="primaryRegular" />
                        {t('pages_profileDetail_sitterInfoBox_criminalBackground')}
                    </VerificationsBulletsStyled>
                ) : null}

                {isSitterPremium && (
                    <PremiumBadgeWrapper>
                        <PremiumBadge textString="components_badges_premium_sitter" />
                    </PremiumBadgeWrapper>
                )}
                {(membershipType === MembershipType.OWNER ||
                    membershipType === MembershipType.COMBINED) &&
                membershipStatus === MembershipStatus.MEMBER ? (
                    <FavouriteButton
                        isFavourite={isFavourited}
                        className="mt-6 md:hidden"
                        busy={isFavouriteButtonLoading()}
                        onClick={() => {
                            onClickFavourite();
                        }}
                    />
                ) : null}

                {hasAnySocial(socialVerifications) && (
                    <SocialLinksDesktopStyled isLast={isPreview}>
                        {/* airbnb */}
                        {airbnbLink(socialVerifications) && (
                            <SitterSocialLink
                                t={t}
                                icon={IconAirbnb}
                                title="pages_profileDetail_sitterInfoBox_social_airbnb"
                                socialLink={airbnbLink(socialVerifications)}
                                viewerIsMember={viewerIsMember}
                                data-testid="airbnb-social-link"
                                isPreview={isPreview}
                            />
                        )}
                        {linkedInLink(socialVerifications) && (
                            <SitterSocialLink
                                t={t}
                                icon={IconLinkedIn}
                                title="pages_profileDetail_sitterInfoBox_social_linkedin"
                                socialLink={linkedInLink(socialVerifications)}
                                viewerIsMember={viewerIsMember}
                                isPreview={isPreview}
                            />
                        )}
                        {instagramLink(socialVerifications) && (
                            <SitterSocialLink
                                t={t}
                                icon={IconInstagram2}
                                title="pages_profileDetail_sitterInfoBox_social_instagram"
                                socialLink={instagramLink(socialVerifications)}
                                viewerIsMember={viewerIsMember}
                                isPreview={isPreview}
                            />
                        )}
                        {facebookLink(socialVerifications) && (
                            <SitterSocialLink
                                t={t}
                                icon={IconFacebook2}
                                title="pages_profileDetail_sitterInfoBox_social_facebook"
                                socialLink={facebookLink(socialVerifications)}
                                viewerIsMember={viewerIsMember}
                                isPreview={isPreview}
                            />
                        )}
                    </SocialLinksDesktopStyled>
                )}

                {!isPreview && (
                    <ZIndexManager layer="stickyFilterBar">
                        <CTAContainerStyled>
                            {inBrowser && (
                                <InviteCTA
                                    t={t}
                                    membershipType={membershipType}
                                    membershipStatus={membershipStatus}
                                    hasApprovedListing={hasApprovedListing}
                                    isLoggedIn={isLoggedIn}
                                    sitterUserId={sitterUserId}
                                    sitterProfileId={sitterProfileId}
                                    ownerListingId={ownerListingId}
                                />
                            )}
                        </CTAContainerStyled>
                    </ZIndexManager>
                )}
            </VerificationsSectionStyled>
        </InfoBoxStyled>
    );
};

export default SitterInfoBox;
