import React from 'react';
import { DatepickerStyled } from './AvailabilityCalendar.style';

const AvailabilityCalendar = ({ availableDates, unavailableDates, translate, defaultMonth }) => (
    <DatepickerStyled
        defaultMonth={defaultMonth}
        translate={translate}
        mobilePagination
        mobileHeaders
        showAvailability
        allowSelecting={false}
        allowHovering={false}
        availableDates={availableDates}
        unavailableDates={unavailableDates}
        showKey={false}
    />
);

AvailabilityCalendar.defaultProps = {
    defaultMonth: new Date(),
};

export default AvailabilityCalendar;
