import React, { useEffect, useMemo, useState, useRef } from 'react';
import { routes } from 'utils/routes';
import PropTypes from 'prop-types';
import { differenceInYears } from 'date-fns';
import { MembershipType, MembershipStatus } from 'api/types';
import { parseDate } from 'api/helpers/format/date';
import { transformReference } from 'api/helpers/transform/references';
import { baseProfile } from 'api/props/common';
import { FlexboxGrid, Col } from 'components/layouts';
import { locationName } from 'api/helpers/format/location';
import { getReviewArray } from 'utils/feedbackReview';
import PageWrapper from 'components/layouts/PageWrapper';
import CollapsableText from 'components/CollapsableText/CollapsableText';
import Tag from 'components/Tag';
import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';
import { P } from 'typography';
import FeedbackReviewList from 'components/FeedbackReviewList';
import BackButton from 'pages/search/components/BackButton';
import { FavouriteButton } from '@ths/design-system';
import {
    useCreateOwnerFavouritesMutation,
    useDeleteOwnerFavouriteMutation,
} from 'api/query/core/owner';

import { scrollViewport } from 'utils/dom';
import {
    DetailsArticle,
    DetailsGallery,
    DetailsSection,
    DetailsLargeSection,
    DetailsSectionHeader,
    DetailsTextSectionHeader,
    DetailsTextSectionLabel,
    DetailsTextSectionContainer,
    DetailsMyExperienceSectionHeader,
    DetailsMyExperienceCollapsableText,
    BODY_COPY_MAX_CHARACTERS,
} from 'components/DetailsPages';
import {
    MyExperienceSection,
    MyExperienceWrapper,
} from 'components/DetailsPages/DetailsPages.style';
import { Events, track } from 'utils/analytics';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import PreferredLocation from './components/PreferredLocation';
import ReferencesList from './components/ReferencesList';
import SitterInfoBox from './components/SitterInfoBox/SitterInfoBox';
import MobileSocialLinks from './components/MobileSocialLinks';
import PetCarousel from '../../containers/ListingDetailPage/components/PetCarousel';

import { getAvailableDateRanges } from './helpers';
import { PROFILE_DETAIL_TESTSID, VALUE_REVIEWS_SCROLL_OFFSET } from './ProfileDetailPage.constants';
import {
    InfoColStyled,
    Subheading,
    SpinnerContainer,
    ProfileDetailSection,
    HeaderOptionsWrapper,
    FooterBreadcrumb,
    DarkIconStyled,
} from './ProfileDetailPage.style';
import CollapsableTags from './CollapsableTags';

const ProfileDetailPage = ({
    t,
    profile,
    reviews,
    membershipType,
    membershipStatus,
    isLoggedIn,
    isPreview,
    hasApprovedListing,
    ownerListingId,
    defaultMonth,
    profileHasLoaded,
    showSittersLookingInYourAreaTestFeatures,
    isFavourited,
    reloadOwnerFavourites,
}) => {
    /**
     * For getting plural vs singular.
     * If we have a partner's first name then there are 2 people travelling otherwise 1.
     */
    const hasPartner = profile?.partner?.firstName ?? false;
    const numberTravellers = hasPartner ? 2 : 1;
    const reviewsObject = (() => {
        if (reviews) {
            return reviews;
        }

        const pastAssignmentsReviews =
            profile && profile.pastAssignments ? getReviewArray(profile.pastAssignments) : [];

        return {
            count: pastAssignmentsReviews.length,
            hasMore: false,
            results: pastAssignmentsReviews,
        };
    })();

    const [createFavourite, createFavouriteResult] = useCreateOwnerFavouritesMutation();
    const [deleteFavourite, deleteFavouriteResult] = useDeleteOwnerFavouriteMutation();
    // Using a state to give instant feedback to the user
    const [localIsFavourited, setLocalIsFavourited] = useState(isFavourited);

    useEffect(() => {
        setLocalIsFavourited(isFavourited);
    }, [isFavourited]);

    // Used to properly trigger the reload saga action
    // on the favourite after the mutation is complete
    useEffect(() => {
        if (createFavouriteResult.isError || deleteFavouriteResult.isError) {
            setLocalIsFavourited(!localIsFavourited);
            createFavouriteResult.reset();
            deleteFavouriteResult.reset();
        }
        if (createFavouriteResult.fulfilledTimeStamp || deleteFavouriteResult.fulfilledTimeStamp) {
            reloadOwnerFavourites();
            createFavouriteResult.reset();
            deleteFavouriteResult.reset();
        }
    }, [createFavouriteResult, deleteFavouriteResult, localIsFavourited, reloadOwnerFavourites]);

    const profileName = hasPartner
        ? `${profile.sitterName} & ${profile.partner.firstName}`
        : profile.sitterName;
    const location =
        locationName(profile.location, true) ||
        (isPreview ? t('pages_profileDetail_defaultLocation') : '');
    const title =
        profile.title ||
        (isPreview
            ? t('pages_profileDetail_defaultTitle')
            : t('pages_profileDetail_sitterInfoBox_defaultTitle', {
                  location,
                  count: hasPartner ? 2 : 1,
              }));
    const [islookingInYourArea, setIslookingInYourArea] = useState(false);
    useEffect(() => {
        const queryString = window.location.search;

        const redirect = new URLSearchParams(queryString).get('r');
        if (redirect && profile.id && (profile.completedReferences || profile.pastAssignments)) {
            window.location.replace(`#${redirect}`);
        }
        const islookingInYourAreaQuery = new URLSearchParams(queryString).get('lookingInYourArea');
        if (islookingInYourAreaQuery === 'true' && showSittersLookingInYourAreaTestFeatures)
            setIslookingInYourArea(true);
    }, [profile, showSittersLookingInYourAreaTestFeatures]);

    const completedReferences = useMemo(() => {
        if (!Array.isArray(profile?.references)) return undefined;

        return (
            profile.references
                // references with a sendAt value have been completed by the reference provider
                .filter(({ sentAt }) => !!sentAt)
                .map((item) => transformReference(item, profileName))
        );
    }, [profile.references, profileName]);

    const reviewsSectionRef = useRef();

    if (!profile.id) {
        return (
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>
        );
    }

    const renderGallery = () => {
        let photos = [];
        if (profile && profile.photos) {
            if (profile.sitterAvatarPhoto) {
                photos = [profile.sitterAvatarPhoto, ...profile.photos];
            } else {
                photos = profile.photos;
            }
        }
        return (
            <DetailsSection data-testid="ProfileDetail_gallerySection">
                <DetailsGallery photos={photos} />
            </DetailsSection>
        );
    };
    const renderPetsCaredFor = () => {
        const { sitterPets } = profile;
        if (profile && sitterPets?.length > 0) {
            return <PetCarousel pets={sitterPets} />;
        }
        return null;
    };

    const getBreadcrumbLinks = () => {
        const breadcrumbHome = {
            id: 'home',
            text: t('navigation_home'),
            to: routes.search.profiles(),
        };

        const breadcrumbCountry = {
            id: 'country-name',
            text: profile.location.countryName,
            to: routes.search.profiles({
                countrySlug: profile.location.countrySlug,
            }),
        };

        const breadcrumbAdmin = {
            id: 'admin1-name',
            text: profile.location.admin1Name,
            to: routes.search.profiles({
                countrySlug: profile.location.countrySlug,
                admin1Slug: profile.location.admin1Slug,
            }),
        };

        const breadcrumbLocationName = {
            id: 'location-name',
            text: profile.location.name,
            to: routes.search.profiles({
                countrySlug: profile.location.countrySlug,
                admin1Slug: profile.location.admin1Slug,
                slug: profile.location.slug,
            }),
        };

        if (!profile.location.admin1Name) {
            return [breadcrumbHome, breadcrumbCountry, breadcrumbLocationName];
        }

        return [breadcrumbHome, breadcrumbCountry, breadcrumbAdmin, breadcrumbLocationName];
    };

    const renderBreadcrumb = () => {
        if (!profile.location) {
            return null;
        }

        const breadcrumbLinks = getBreadcrumbLinks();

        return <Breadcrumb disableLastItem={false} items={breadcrumbLinks} />;
    };

    const getAge = (birthDate) => {
        if (!birthDate) return null;

        const birthDateParsed = parseDate(birthDate);
        const ageInYears = differenceInYears(Date.now(), birthDateParsed);

        if (ageInYears >= 65) {
            return '65+';
        }
        return `${ageInYears}`;
    };

    const renderAges = ({ sitterName, birthDate, partner }) => {
        if (!birthDate) return null;

        if (partner && partner.firstName) {
            if (partner.birthDate) {
                return `${sitterName} ${getAge(birthDate)} and ${partner.firstName} ${getAge(
                    partner.birthDate
                )}`;
            }

            return `${sitterName} ${getAge(birthDate)}`;
        }

        return getAge(birthDate);
    };

    const getOccupations = (occupation, partner) => {
        if (partner && partner.occupation) {
            return `${occupation} and ${partner.occupation}`;
        }

        return occupation;
    };

    const getTravelling = (partner, withChildren) => {
        if (withChildren) {
            return t('pages_profileDetail_travellingWithChildren');
        }

        if (partner && partner.firstName) {
            return t('pages_profileDetail_travellingWithPartner');
        }

        return t('pages_profileDetail_travellingOnOwn');
    };

    const scrollToReviews = () => {
        if (reviewsSectionRef.current)
            scrollViewport(reviewsSectionRef.current, VALUE_REVIEWS_SCROLL_OFFSET);
    };

    const toggleFavourite = () => {
        if (localIsFavourited) {
            deleteFavourite({ profileId: profile.id });
            setLocalIsFavourited(false);
            track(
                Events.OWNER_REMOVED_FAVOURITE.create({
                    id: profile.id,
                })
            );
        } else {
            createFavourite({ createOwnerFavourite: { id: profile.id } });
            setLocalIsFavourited(true);
            track(
                Events.OWNER_ADDED_FAVOURITE.create({
                    id: profile.id,
                })
            );
        }
    };

    // function to check if the favourite mutation is loading and set the button state to loading while it is
    const isFavouriteMutationLoading = () =>
        createFavouriteResult.isLoading || deleteFavouriteResult.isLoading;

    const renderBodyDetails = () => {
        let unavailableDates = [];
        let availableDates = [];

        if (profile) {
            const dates = getAvailableDateRanges({
                availableDates: profile.availableDates,
                unavailableDates: profile.unavailableDates,
            });

            unavailableDates = dates.unavailableDates;
            availableDates = dates.availableDates;
        }

        return (
            <>
                <DetailsSection data-testid="ProfileDetail_introductionSection">
                    {profile.introduction || isPreview ? (
                        <>
                            <DetailsTextSectionHeader>
                                {t('pages_profileDetail_introduction')}
                            </DetailsTextSectionHeader>
                            <CollapsableText
                                method="maxCharacters"
                                openButtonText={t('pages_profileDetail_showMoreText')}
                                closeButtonText={t('pages_profileDetail_showLessText')}
                                text={profile.introduction || ''}
                                maxCharacters={BODY_COPY_MAX_CHARACTERS}
                            >
                                {({ text }) => <P>{text}</P>}
                            </CollapsableText>
                        </>
                    ) : null}
                </DetailsSection>
                <DetailsSection data-testid="ProfileDetail_motivationSection">
                    {profile.motivation || isPreview ? (
                        <>
                            <DetailsTextSectionHeader>
                                {t('pages_profileDetail_motivation', {
                                    count: numberTravellers,
                                })}
                            </DetailsTextSectionHeader>
                            <CollapsableText
                                method="maxCharacters"
                                openButtonText={t('pages_profileDetail_showMoreText')}
                                closeButtonText={t('pages_profileDetail_showLessText')}
                                text={profile.motivation || ''}
                                maxCharacters={BODY_COPY_MAX_CHARACTERS}
                            >
                                {({ text }) => <P>{text}</P>}
                            </CollapsableText>
                        </>
                    ) : null}
                </DetailsSection>

                {!isPreview && membershipStatus === MembershipStatus.MEMBER ? (
                    <DetailsLargeSection data-testid={PROFILE_DETAIL_TESTSID.calendar}>
                        <DetailsSectionHeader>
                            {t('pages_profileDetail_availabilityHeading')}
                        </DetailsSectionHeader>
                        <AvailabilityCalendar
                            translate={t}
                            unavailableDates={unavailableDates}
                            availableDates={availableDates}
                            defaultMonth={defaultMonth}
                        />
                    </DetailsLargeSection>
                ) : null}

                <DetailsSection>
                    {!islookingInYourArea &&
                    ((profile.favouriteCountries && profile.favouriteCountries.length > 0) ||
                        isPreview) ? (
                        <PreferredLocation
                            translate={t}
                            favouriteCountries={profile.favouriteCountries}
                        />
                    ) : null}
                </DetailsSection>

                <DetailsSection>
                    <DetailsSectionHeader>
                        {t('pages_profileDetail_detailHeading')}
                    </DetailsSectionHeader>
                    {profile.birthDate || isPreview ? (
                        <ProfileDetailSection>
                            <Subheading>{t('pages_profileDetail_ageHeading')}</Subheading>
                            <div>
                                {renderAges({
                                    sitterName: profile.sitterName,
                                    birthDate: profile.birthDate,
                                    partner: hasPartner ? profile.partner : null,
                                })}
                            </div>
                        </ProfileDetailSection>
                    ) : null}
                    {profile.occupation || isPreview ? (
                        <ProfileDetailSection>
                            <Subheading>{t('pages_profileDetail_occupationHeading')}</Subheading>
                            <div>
                                {getOccupations(
                                    profile.occupation,
                                    hasPartner ? profile.partner : null
                                )}
                            </div>
                        </ProfileDetailSection>
                    ) : null}
                    {hasPartner || isPreview ? (
                        <ProfileDetailSection>
                            <Subheading>{t('pages_profileDetail_travellingHeading')}</Subheading>
                            <div>
                                {getTravelling(
                                    hasPartner ? profile.partner : null,
                                    profile.withChildren
                                )}
                            </div>
                        </ProfileDetailSection>
                    ) : null}
                </DetailsSection>
            </>
        );
    };

    const renderMyExperience = () => {
        const showYears = !!profile.yearsOfExperience;
        const years = profile.yearsOfExperience >= 10 ? '10+' : profile.yearsOfExperience;

        return (
            <MyExperienceSection data-testid="ProfileDetail_relevantExperienceSection">
                {profile.relevantExperience || isPreview ? (
                    <>
                        <DetailsTextSectionContainer>
                            <DetailsMyExperienceSectionHeader>
                                {t('pages_profileDetail_relevantExperience', {
                                    count: numberTravellers,
                                })}
                            </DetailsMyExperienceSectionHeader>
                            {showYears && (
                                <DetailsTextSectionLabel>
                                    {t('pages_profileDetail_yearsExperience', {
                                        years,
                                    })}
                                </DetailsTextSectionLabel>
                            )}
                        </DetailsTextSectionContainer>
                        <CollapsableText
                            method="maxCharacters"
                            openButtonText={t('pages_profileDetail_showMoreText')}
                            closeButtonText={t('pages_profileDetail_showLessText')}
                            text={profile.relevantExperience || ''}
                            maxCharacters={BODY_COPY_MAX_CHARACTERS}
                        >
                            {({ text }) => (
                                <DetailsMyExperienceCollapsableText>
                                    {text}
                                </DetailsMyExperienceCollapsableText>
                            )}
                        </CollapsableText>
                    </>
                ) : null}
            </MyExperienceSection>
        );
    };

    const renderPetCareExperience = () => {
        const { skills } = profile;
        return (
            <MyExperienceSection>
                {skills && skills.length > 0 ? (
                    <>
                        <CollapsableTags data-testid="petExperienceContainer" count={skills.length}>
                            {skills.map((skill) => (
                                <Tag key={skill}>
                                    <DarkIconStyled animal={skill} />
                                    {skill === 'medication'
                                        ? t(`web_skillType_${skill}`)
                                        : t(`web_petType_${skill}`)}
                                </Tag>
                            ))}
                        </CollapsableTags>
                    </>
                ) : null}
            </MyExperienceSection>
        );
    };

    return (
        <PageWrapper>
            <DetailsArticle>
                <HeaderOptionsWrapper>
                    <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
                        <Col
                            span={12}
                            largeTablet={12}
                            largeDesktop={10}
                            wide={8}
                            className="flex items-center"
                        >
                            <BackButton />
                            {!isPreview && !isLoggedIn ? renderBreadcrumb() : null}
                            {(membershipType === MembershipType.OWNER ||
                                membershipType === MembershipType.COMBINED) &&
                            membershipStatus === MembershipStatus.MEMBER ? (
                                <FavouriteButton
                                    isFavourite={localIsFavourited}
                                    className="ml-auto hidden md:flex"
                                    busy={isFavouriteMutationLoading()}
                                    onClick={() => {
                                        toggleFavourite();
                                    }}
                                />
                            ) : null}
                        </Col>
                    </FlexboxGrid>
                </HeaderOptionsWrapper>

                <FlexboxGrid justify={FlexboxGrid.Justify.CENTER}>
                    <InfoColStyled span={12} largeTablet={5} largeDesktop={4} wide={3}>
                        <SitterInfoBox
                            sitterName={profile.sitterName}
                            partnerName={hasPartner ? profile.partner.firstName : null}
                            title={title}
                            location={location}
                            reviewsCount={profile.reviewsCount}
                            referencesCount={completedReferences ? completedReferences.length : 0}
                            completedVerifications={profile.completedVerifications}
                            membershipType={membershipType}
                            membershipStatus={membershipStatus}
                            hasApprovedListing={hasApprovedListing}
                            isLoggedIn={isLoggedIn}
                            sitterUserId={profile.sitterId}
                            sitterProfileId={profile.id}
                            sitterMembershipTier={profile.sitterMembershipTier}
                            ownerListingId={ownerListingId}
                            socialVerifications={profile.socialVerifications}
                            viewerIsMember={membershipStatus === MembershipStatus.MEMBER}
                            isPreview={isPreview}
                            profileHasLoaded={profileHasLoaded}
                            islookingInYourArea={islookingInYourArea}
                            onClickReviews={scrollToReviews}
                            isFavourited={localIsFavourited}
                            isFavouriteButtonLoading={isFavouriteMutationLoading}
                            onClickFavourite={toggleFavourite}
                        />
                    </InfoColStyled>

                    <Col span={12} largeTablet={7} largeDesktop={6} wide={5}>
                        {renderGallery()}
                        <MyExperienceWrapper>
                            {renderMyExperience()}
                            {renderPetCareExperience()}
                            {renderPetsCaredFor()}
                        </MyExperienceWrapper>
                        <MobileSocialLinks
                            t={t}
                            profileName={profileName}
                            socialVerifications={profile.socialVerifications}
                            viewerIsMember={membershipStatus === MembershipStatus.MEMBER}
                        />

                        {reviewsObject.count > 0 ? (
                            <DetailsLargeSection
                                data-testid="ProfileDetail_reviewsSection"
                                ref={reviewsSectionRef}
                            >
                                <DetailsSectionHeader id="reviews">
                                    {`${t('pages_profileDetail_reviews')} (${reviewsObject.count})`}
                                </DetailsSectionHeader>
                                <FeedbackReviewList
                                    items={reviewsObject.results}
                                    variant={FeedbackReviewList.Variant.REVIEW}
                                    translate={t}
                                    replyAvatar={
                                        profile.sitterAvatarPhoto
                                            ? profile.sitterAvatarPhoto.publicId
                                            : ''
                                    }
                                    replyName={profileName}
                                    replyIsRedacted={profile.sitterIsRedacted}
                                    /**
                                     * This value is the height to the Reviews section heading.
                                     * This is so that when the page changes then viewport
                                     * scroll to just about show the section heading.
                                     */
                                    scrollOffset={-128}
                                />
                            </DetailsLargeSection>
                        ) : null}

                        {completedReferences && completedReferences.length > 0 ? (
                            <DetailsLargeSection data-testid="ProfileDetail_referencesSection">
                                <DetailsSectionHeader id="references">{`${t(
                                    'pages_profileDetail_references'
                                )} (${completedReferences.length})`}</DetailsSectionHeader>
                                <ReferencesList
                                    translate={t}
                                    references={completedReferences}
                                    scrollOffset={-128}
                                />
                            </DetailsLargeSection>
                        ) : null}

                        {renderBodyDetails()}

                        {!isPreview && isLoggedIn ? (
                            <FooterBreadcrumb>{renderBreadcrumb()}</FooterBreadcrumb>
                        ) : null}
                    </Col>
                </FlexboxGrid>
            </DetailsArticle>
        </PageWrapper>
    );
};

ProfileDetailPage.propTypes = {
    /**
     * Object containing details of the profile.
     */
    profile: PropTypes.shape(baseProfile).isRequired,
    /**
     * The current signed in users membershipType. Not needed when isPreview={true}
     */
    membershipType: PropTypes.oneOf(MembershipType).isRequired,
    /**
     * The current signed in users membershipStatus. Not needed when isPreview={true}
     */
    membershipStatus: PropTypes.oneOf(MembershipStatus).isRequired,
    /**
     * If the current signed in user has an approved listing. Not needed when isPreview={true}
     */
    hasApprovedListing: PropTypes.bool.isRequired,
    /**
     * If there even is a signed in user at all. Not needed when isPreview={true}
     */
    isLoggedIn: PropTypes.bool.isRequired,
    /**
     * id of an approved listing belonging to the current owner member. Not needed when isPreview={true}
     */
    ownerListingId: PropTypes.number.isRequired,
    /**
     * TODO: Remove this? It never seems to be used
     */
    defaultMonth: PropTypes.string.isRequired,
    /**
     * Check to see if component is being used as a preview
     */
    isPreview: PropTypes.bool.isRequired,
    profileHasLoaded: PropTypes.bool.isRequired,
    showSittersLookingInYourAreaTestFeatures: PropTypes.bool.isRequired,
    /**
     * Translate function.
     */
    t: PropTypes.func.isRequired,
};
export default ProfileDetailPage;
