import React from 'react';
import { track, Events } from 'utils/analytics';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { routes } from 'utils/routes';
import { matchProfileDetail, matchListingDetail, matchEditProfilePreview } from 'utils/utils';
import { Button } from 'components/buttons';
import { ButtonStyled, IconStyled, LinkStyled } from './BackButton.style';

const trackBackButtonClick = () => {
    track(Events.BACK_BUTTON_CLICKED.create());
};

const BackButton = ({ children, onClick }) => {
    const backClick = () => {
        trackBackButtonClick();
        onClick();
    };
    return (
        <ButtonStyled onClick={backClick} variant={Button.Variant.FLAT}>
            <IconStyled size="small" />
            {children}
        </ButtonStyled>
    );
};

const BackLink = ({ to, children }) => (
    <LinkStyled to={to} onClick={trackBackButtonClick}>
        <IconStyled size="small" />
        {children}
    </LinkStyled>
);

const userStates = {
    fromEmptySearchListing: 'fromEmptySearchListing', // no filters applied
    fromSearchListing: 'fromSearchListing',
    fromEmptySearchProfile: 'fromEmptySearchProfile',
    fromSearchProfile: 'fromSearchProfile',
    fromNonSearchProfile: 'fromNonSearchProfile',
    fromNonSearchListing: 'fromNonSearchListing',
    fromProfileDetail: 'fromProfileDetail',
    fromListingDetail: 'fromListingDetail',
    fromEditProfile: 'fromEditProfile',
    fromFavourites: 'fromFavourites',
};

const getUserState = ({ previousLocation, location }) => {
    if (previousLocation) {
        if (matchProfileDetail(previousLocation.pathname)) {
            return userStates.fromProfileDetail;
        }

        if (matchListingDetail(previousLocation.pathname)) {
            return userStates.fromListingDetail;
        }

        if (previousLocation.pathname === routes.search.profiles()) {
            if (previousLocation.search.includes('q=')) {
                return userStates.fromSearchProfile;
            }
            return userStates.fromEmptySearchProfile;
        }
        if (previousLocation.pathname === routes.search.listings()) {
            if (previousLocation.search.includes('q=')) {
                return userStates.fromSearchListing;
            }
            return userStates.fromEmptySearchListing;
        }
        if (previousLocation.pathname === routes.user.favourites()) {
            return userStates.fromFavourites;
        }
    }
    if (location) {
        if (matchListingDetail(location.pathname)) {
            if (location.search.includes('q=') || location.search.includes('routeParams=')) {
                return userStates.fromSearchListing;
            }
            return userStates.fromNonSearchListing;
        }
        if (matchProfileDetail(location.pathname)) {
            if (location.search.includes('q=') || location.search.includes('routeParams=')) {
                return userStates.fromSearchProfile;
            }
            return userStates.fromNonSearchProfile;
        }
        if (matchEditProfilePreview(location.pathname)) {
            return userStates.fromEditProfile;
        }
    }
    return null;
};

const BackButtonContainer = ({ previousLocation, location, history, t }) => {
    const userState = getUserState({ previousLocation, location });
    if (userState === userStates.fromProfileDetail) {
        return (
            <BackButton data-testid="from-profile-detail" onClick={history.goBack}>
                {t('components_backButton')}
            </BackButton>
        );
    }

    if (userState === userStates.fromListingDetail) {
        return (
            <BackButton data-testid="from-listing-detail" onClick={history.goBack}>
                {t('components_backButton')}
            </BackButton>
        );
    }

    if (userState === userStates.fromEditProfile) {
        return (
            <BackButton data-testid="from-edit-profile" onClick={history.goBack}>
                {t('components_backButton_fromEditProfile')}
            </BackButton>
        );
    }

    if (userState === userStates.fromFavourites) {
        return (
            <BackButton data-testid="from-favourites" onClick={history.goBack}>
                {t('components_backButton_fromFavourites')}
            </BackButton>
        );
    }

    if (userState === userStates.fromEmptySearchListing) {
        return (
            <BackButton data-testid="from-empty-search-listing" onClick={history.goBack}>
                {t('components_backButton_fromEmptySearchListing')}
            </BackButton>
        );
    }
    if (userState === userStates.fromEmptySearchProfile) {
        return (
            <BackButton data-testid="from-empty-search-profile" onClick={history.goBack}>
                {t('components_backButton_fromEmptySearchProfile')}
            </BackButton>
        );
    }
    if (userState === userStates.fromSearchListing || userState === userStates.fromSearchProfile) {
        const searchParams = new URLSearchParams(location.search);
        const routeParams = searchParams.get('routeParams')
            ? JSON.parse(searchParams.get('routeParams'))
            : {};
        searchParams.delete('routeParams');
        const searchHref = `${
            userState === userStates.fromSearchListing
                ? routes.search.listings(routeParams)
                : routes.search.profiles(routeParams)
        }?${searchParams.toString()}`;

        return (
            <BackLink data-testid="from-search" to={searchHref}>
                {t('components_backButton_fromSearchListing')}
            </BackLink>
        );
    }
    if (userState === userStates.fromNonSearchListing) {
        return (
            <BackLink data-testid="from-non-search-listing" to={routes.search.listings()}>
                {t('components_backButton_fromNonSearchListing')}
            </BackLink>
        );
    }
    if (userState === userStates.fromNonSearchProfile) {
        return (
            <BackLink data-testid="from-non-search-profile" to={routes.search.profiles()}>
                {t('components_backButton_fromNonSearchProfile')}
            </BackLink>
        );
    }
    return null;
};

const mapStateToProps = (state) => ({
    previousLocation: state.router.location.previousLocation,
    location: state.router.location,
});

const ConnectedBackButton = withRouter(connect(mapStateToProps)(BackButtonContainer));
export { BackButtonContainer, ConnectedBackButton as default };
