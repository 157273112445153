import React from 'react';
import { IconAirbnb, IconFacebook2, IconLinkedIn } from 'components/Icon';
import SitterSocialLink from '../SitterSocialLink';
import {
    airbnbLink,
    linkedInLink,
    instagramLink,
    facebookLink,
    hasAnySocial,
} from '../SitterInfoBox/helpers';
import { MobileWrapperStyled, HeaderStyled } from './MobileSocialLinks.style';

const MobileSocialLinks = ({ profileName, t, viewerIsMember, socialVerifications }) => (
    <>
        {hasAnySocial(socialVerifications) && (
            <MobileWrapperStyled>
                <HeaderStyled>
                    {t('pages_profileDetail_social_title', {
                        name: profileName,
                    })}
                </HeaderStyled>

                <div>
                    {/* airbnb */}
                    {airbnbLink(socialVerifications) && (
                        <SitterSocialLink
                            t={t}
                            icon={IconAirbnb}
                            title="pages_profileDetail_sitterInfoBox_social_airbnb"
                            socialLink={airbnbLink(socialVerifications)}
                            viewerIsMember={viewerIsMember}
                            data-testid="airbnb-social-link"
                        />
                    )}
                    {linkedInLink(socialVerifications) && (
                        <SitterSocialLink
                            t={t}
                            icon={IconLinkedIn}
                            title="pages_profileDetail_sitterInfoBox_social_linkedin"
                            socialLink={linkedInLink(socialVerifications)}
                            viewerIsMember={viewerIsMember}
                        />
                    )}
                    {instagramLink(socialVerifications) && (
                        <SitterSocialLink
                            t={t}
                            icon={IconInstagram2}
                            title="pages_profileDetail_sitterInfoBox_social_instagram"
                            socialLink={instagramLink(socialVerifications)}
                            viewerIsMember={viewerIsMember}
                        />
                    )}
                    {facebookLink(socialVerifications) && (
                        <SitterSocialLink
                            t={t}
                            icon={IconFacebook2}
                            title="pages_profileDetail_sitterInfoBox_social_facebook"
                            socialLink={facebookLink(socialVerifications)}
                            viewerIsMember={viewerIsMember}
                        />
                    )}
                </div>
            </MobileWrapperStyled>
        )}
    </>
);

export default MobileSocialLinks;
