import React, { useMemo, useState } from 'react';

import { IconCheckCircle } from 'components/Icon';
import { StaticRating } from 'components/Rating';
import CollapsableText from 'components/CollapsableText/CollapsableText';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import ContactableExplainerModal from './components/ContactableExplainerModal';

import {
    WrapperStyled,
    HeaderStyled,
    LookedAfterStyled,
    CharacteristicContainerStyled,
    CharacteristicStyled,
    FooterStyled,
    ReferenceOriginStyled,
    DateStyled,
    HappyToBeContactedStyled,
} from './ReferenceCard.style';

const ReferenceCard = ({
    t,

    item: {
        characteristics,
        ratingAverage,
        happyToBeContacted,
        lookedAfter,
        sitterName,
        refereeConnection,
        refereeName,
        reference,
        date,
    },

    className,
}) => {
    const [isContactableExplainerModalVisible, setIsContactableExplainerModalVisible] =
        useState(false);

    const lookedAfterText = useMemo(() => {
        if (!lookedAfter) return undefined;

        return t(`components_ReferenceCard_looked_${lookedAfter}`, {
            sitterName,
            refereeName,
        });
    }, [lookedAfter, refereeName, sitterName, t]);

    return (
        <WrapperStyled className={className}>
            <HeaderStyled>
                <UserAvatar letter={refereeName.slice(0, 1)} />

                <div>
                    <p>{refereeName}</p>
                    <StaticRating value={ratingAverage} size="sm" />
                </div>
            </HeaderStyled>

            {lookedAfterText && <LookedAfterStyled>{lookedAfterText}</LookedAfterStyled>}
            <CollapsableText method="maxHeight" t={t}>
                {reference}
            </CollapsableText>

            <CharacteristicContainerStyled>
                {characteristics?.map((characteristic) => (
                    <CharacteristicStyled key={characteristic}>
                        {t(`components_ReferenceCard_characteristic_${characteristic}`)}
                    </CharacteristicStyled>
                ))}
            </CharacteristicContainerStyled>

            <FooterStyled>
                <div>
                    <ReferenceOriginStyled>
                        {t(`components_ReferenceCard_referenceOrigin_${refereeConnection}`, {
                            sitterName,
                        })}
                    </ReferenceOriginStyled>
                    <DateStyled>{date}</DateStyled>
                </div>
                {happyToBeContacted && (
                    <>
                        <HappyToBeContactedStyled
                            onClick={() => setIsContactableExplainerModalVisible(true)}
                        >
                            <IconCheckCircle />
                            <span>{t('components_ReferenceCard_happyToBeContacted')}</span>
                        </HappyToBeContactedStyled>
                        <ContactableExplainerModal
                            isOpen={isContactableExplainerModalVisible}
                            onClose={() => setIsContactableExplainerModalVisible(false)}
                        />
                    </>
                )}
            </FooterStyled>
        </WrapperStyled>
    );
};

export default ReferenceCard;
