import React, { useCallback, useRef, useState } from 'react';
import PaginatedList from 'components/PaginatedList';
import { scrollViewport } from 'utils/dom';

import { ButtonStyled, ReferenceCardStyled } from './ReferencesList.style';

const ReferencesList = ({ scrollOffset, references, translate }) => {
    const paginatedListRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const showPaginatedList = () => setIsOpen(true);

    const scrollPage = useCallback(() => {
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                scrollViewport(paginatedListRef.current, scrollOffset);
            });
        });
    }, [scrollOffset]);

    const renderItem = useCallback(
        (item) => <ReferenceCardStyled key={item.key} t={translate} item={item} />,
        [translate]
    );

    if (!isOpen) {
        const shouldShowSeeMoreButton = references.length > 1;

        return (
            <>
                {renderItem(references[0])}
                {shouldShowSeeMoreButton && (
                    <ButtonStyled
                        data-testid="referencesList_SeeMoreButton"
                        onClick={showPaginatedList}
                    >
                        {translate('components_ReferencesList_moreReferences')}
                    </ButtonStyled>
                )}
            </>
        );
    }

    return (
        <PaginatedList
            items={references}
            perPage={5}
            maxPagesToDisplay={5}
            ariaPageLabel={(page) => translate('components_ReferencesList_gotoPage', { page })}
            ariaCurrentPageLabel={(page) =>
                translate('components_ReferencesList_currentPage', { page })
            }
            ariaPreviousPageLabel={translate('components_ReferencesList_previousPage')}
            ariaNextPageLabel={translate('components_ReferencesList_nextPage')}
            onItemClick={scrollPage}
            ref={paginatedListRef}
        >
            {({ pageItems }) => pageItems.map(renderItem)}
        </PaginatedList>
    );
};

export default ReferencesList;
