import styled from 'styled-components';
import { DetailsSectionHeader, SIDEBAR_BREAKPOINT } from 'components/DetailsPages';

const MobileWrapperStyled = styled.div`
    margin-top: ${({ theme }) => theme.spacing[6]};
    display: block;
    padding-bottom: ${({ theme }) => theme.spacing[2.5]};

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        display: none;
    }
`;

const HeaderStyled = styled(DetailsSectionHeader)`
    margin-top: 0;
`;

export { MobileWrapperStyled, HeaderStyled };
