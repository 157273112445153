import {
    airbnbSlugToURL,
    linkedInSlugToURL,
    instagramSlugToURL,
    facebookSlugToURL,
} from 'utils/socialVerifications';

// eslint-disable-next-line consistent-return
export const airbnbLink = (socialVerifications) => {
    if (
        !!socialVerifications &&
        !!socialVerifications.airbnb &&
        !!socialVerifications.airbnb.platformIdentifier
    ) {
        return airbnbSlugToURL(socialVerifications.airbnb.platformIdentifier);
    }
};
// eslint-disable-next-line consistent-return
export const linkedInLink = (socialVerifications) => {
    if (
        !!socialVerifications &&
        !!socialVerifications.linkedin &&
        !!socialVerifications.linkedin.platformIdentifier
    ) {
        return linkedInSlugToURL(socialVerifications.linkedin.platformIdentifier);
    }
};
// eslint-disable-next-line consistent-return
export const instagramLink = (socialVerifications) => {
    if (
        !!socialVerifications &&
        !!socialVerifications.instagram &&
        !!socialVerifications.instagram.platformIdentifier
    ) {
        return instagramSlugToURL(socialVerifications.instagram.platformIdentifier);
    }
};
// eslint-disable-next-line consistent-return
export const facebookLink = (socialVerifications) => {
    if (
        !!socialVerifications &&
        !!socialVerifications.facebook &&
        !!socialVerifications.facebook.platformIdentifier
    ) {
        return facebookSlugToURL(socialVerifications.facebook.platformIdentifier);
    }
};

export const hasAnySocial = (socialVerifications) =>
    !!airbnbLink(socialVerifications) ||
    !!linkedInLink(socialVerifications) ||
    !!instagramLink(socialVerifications) ||
    !!facebookLink(socialVerifications);
