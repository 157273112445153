import styled, { css } from 'styled-components';
import { smallTextStyles, h2Styles, h2LargeStyles, pStyles } from 'typography';
import { zIndex } from 'components/ZIndexManager';
import { IconRating, IconCheck, IconLook } from 'components/Icon';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import { SIDEBAR_BREAKPOINT } from 'components/DetailsPages';

const ReviewIconStyled = styled(IconRating).attrs({
    size: 'large',
})`
    margin-right: ${({ theme }) => theme.spacing[2]};

    & > svg {
        fill: ${({ theme }) => theme.palette.yellow};
    }
`;
const LookIconStyled = styled(IconLook).attrs({
    size: 'large',
})`
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

const RatingStyled = styled.span`
    display: flex;
    margin: ${({ theme }) => `${theme.spacing[2]} 0`};
`;

const ClickableReviewsStyled = styled.span`
    ${pStyles}

    display: flex;
    color: ${({ theme }) => theme.palette.primaryRegular};
    margin: ${({ theme }) => `${theme.spacing[2]} 0`};

    &:hover {
        text-decoration: underline;
    }
`;

const LocationStyled = styled.div`
    ${smallTextStyles};
    color: ${({ theme }) => theme.palette.textLight};
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[3]};
    }
`;

const VerificationsSectionStyled = styled.div`
    margin: ${({ theme }) => `${theme.spacing[3]} 0 0`};

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        margin: ${({ theme }) => `${theme.spacing[3]} 0`};
    }
`;

const VerificationsBulletsStyled = styled.p(
    ({ theme: { palette, spacing }, isSitterPremium }) => css`
        ${smallTextStyles};
        color: ${palette.textLight};
        display: flex;
        margin-top: 0;
        margin-bottom: ${spacing[0.5]};

        &:last-of-type {
            margin-bottom: ${isSitterPremium ? spacing[3] : 0};
        }

        @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
            &:last-of-type {
                margin-bottom: ${isSitterPremium ? spacing[3] : spacing[4]};
            }
        }
    `
);

const VerificationCheckIconStyled = styled(IconCheck)`
    margin-right: ${({ theme }) => `${theme.spacing[2]}`};
    width: 1.5rem;
    height: 1.25rem;
`;

const InfoBoxStyled = styled.div`
    margin: 0;

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[3]} 0`};
        border: 1px solid ${({ theme }) => theme.palette.borderLight};
        border-radius: ${({ theme }) => theme.borderRadius.regular};

        position: sticky;
        top: 5rem;
    }

    /* this is avoid losing the 'invite sitter' button when the info box is too long */
    /* currently set to 660px */
    @media screen and (max-height: 41.25em) {
        position: static;
    }
`;

// we want to have the profile name title to be wrapped in an h2 tag for SEO purposes
// but still keep the styling the same
const TitleStyled = styled.h2`
    ${h2Styles};
    margin-top: 0;
    margin-bottom: 0.67em;

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        ${h2LargeStyles};
        margin-top: 0;
    }
`;

const CTAContainerStyled = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: ${zIndex};

    background-color: white;
    border-top: 1px solid ${({ theme }) => theme.palette.border};

    width: 100%;
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[3]}`};

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        position: static;
        padding: 0;
        border: none;
    }
`;

const CTALinkStyled = styled(Link)`
    width: 100%;
`;

const ButtonStyled = styled(Button)`
    width: 100%;
`;

const CTATextLargeTabletStyled = styled.span`
    display: none;

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        display: block;
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: none;
    }
`;

const CTATextStyled = styled.span`
    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        display: none;
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: block;
    }
`;

// we want to have the profile name title to be wrapped in an h1 tag for SEO purposes
// but still keep the styling the same
const DescriptionTextStyled = styled.h1(
    ({ theme: { spacing } }) => css`
        ${pStyles};
        margin: ${spacing[2]} ${spacing[0]};
    `
);

const SocialLinksDesktopStyled = styled.div`
    display: none;

    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        display: block;
        padding: ${({ theme }) => `${theme.spacing[2]} 0`};
        margin-bottom: ${({ theme }) => theme.spacing[3]};
        border-top: 1px solid ${({ theme }) => theme.palette.borderLight};
        border-bottom: ${({ theme, isLast }) =>
            isLast ? 'none' : `1px solid ${theme.palette.borderLight}`};
    }
`;

const PremiumBadgeWrapper = styled.div(
    ({ theme: { spacing } }) => css`
        display: flex;

        @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
            margin-bottom: ${spacing[3]};
        }
    `
);

const LoadingBox = styled.div(
    ({ theme: { palette, borderRadius, spacing } }) => css`
        ::before {
            // Just to take up some space so it renders
            content: '\\a';
        }

        margin: 0;
        margin-bottom: ${spacing[1]};
        height: 20px;
        background-color: ${palette.borderLight};
        border-radius: ${borderRadius.small};
        width: 100%;

        &:last-child {
            margin-bottom: ${spacing[2]};
        }
    `
);

export {
    InfoBoxStyled,
    LocationStyled,
    ReviewIconStyled,
    RatingStyled,
    ClickableReviewsStyled,
    VerificationsSectionStyled,
    VerificationsBulletsStyled,
    VerificationCheckIconStyled,
    TitleStyled,
    CTALinkStyled,
    CTAContainerStyled,
    CTATextLargeTabletStyled,
    CTATextStyled,
    ButtonStyled,
    DescriptionTextStyled,
    SocialLinksDesktopStyled,
    PremiumBadgeWrapper,
    LoadingBox,
    LookIconStyled,
};
