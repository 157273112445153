/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Button } from 'components/buttons';
import ReferenceCard from 'components/ReferenceCard';

const ButtonStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[3]};
    display: flex;
    align-items: center;
`;

const ReferenceCardStyled = styled(ReferenceCard)`
    &:first-of-type {
        padding-top: 0;
    }
`;

export { ButtonStyled, ReferenceCardStyled };
