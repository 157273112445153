import styled, { css } from 'styled-components';
import Dialog from 'components/Dialog';
import Blanket from 'components/Blanket';

export const DialogStyled = styled(Dialog).attrs({
    variant: Dialog.Variant.mobileFullscreenDesktopCentered,
    blanketVariant: Blanket.Variant.LIGHT,
})(
    ({ theme: { borderRadius } }) => css`
        border-radius: ${borderRadius.regular};
    `
);

export const ModalContents = styled.div(
    ({ theme: { spacing, typography, palette, fontSizes, screenSize } }) => css`
        padding: ${spacing[8]} ${spacing[3]} ${spacing[3]} ${spacing[3]};

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            padding: ${spacing[6]};
        }

        h4 {
            ${typography.h4};
            font-size: ${fontSizes.medLarge};
            line-height: 1.75;
            color: ${palette.text};
            margin: 0;
        }

        p {
            ${typography.p};
            color: ${palette.text};
            margin: ${spacing[2]} 0 0 0;
        }
    `
);

export const CloseButton = styled.button(
    ({ theme: { spacing, palette, screenSize } }) => css`
        border: 0;
        outline: 0;
        padding: 0;
        position: absolute;
        right: unset;
        left: ${spacing[3]};
        top: ${spacing[3]};

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            right: ${spacing[3]};
            left: unset;
        }

        cursor: pointer;

        & svg {
            fill: ${palette.text};
        }
    `
);
