import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconClose } from 'components/Icon';
import Portal from 'components/Portal/Portal';

import { DialogStyled, ModalContents, CloseButton } from './ContactableExplainerModal.style';

const ContactableExplainerModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    return (
        <Portal>
            <DialogStyled onCloseHandler={onClose} isOpen={isOpen}>
                <ModalContents>
                    <CloseButton onClick={onClose}>
                        <IconClose size="medium" />
                    </CloseButton>

                    <h4>{t('components_ReferenceCard_ContactableExplainerModal_title')}</h4>
                    <p>{t('components_ReferenceCard_ContactableExplainerModal_body')}</p>
                </ModalContents>
            </DialogStyled>
        </Portal>
    );
};

export default ContactableExplainerModal;
