import styled from 'styled-components';
import { P, SmallText } from 'typography';

const WrapperStyled = styled.div`
    padding-top: ${({ theme }) => theme.spacing[4]};
    padding-bottom: ${({ theme }) => theme.spacing[4]};
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[100]}`};
`;

const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    > div {
        > p {
            ${({ theme }) => theme.typography.p};
            font-weight: ${({ theme }) => theme.fontWeights.bold};

            margin: 0;
            margin-bottom: ${({ theme }) => theme.spacing[1]};
        }
    }
`;

const LookedAfterStyled = styled(P)`
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const CharacteristicContainerStyled = styled.div`
    display: flex;
    flex-direction: row;
`;

const CharacteristicStyled = styled(SmallText)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.accent};

    display: block;

    padding-top: ${({ theme }) => theme.spacing[1]};
    padding-left: ${({ theme }) => theme.spacing[2]};
    padding-bottom: ${({ theme }) => theme.spacing[1]};
    padding-right: ${({ theme }) => theme.spacing[2]};

    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-right: ${({ theme }) => theme.spacing[2]};

    border-radius: 100px;

    background-color: ${({ theme }) => theme.palette.grey[100]};
`;

const FooterStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: ${({ theme }) => theme.spacing[3]};

    > div {
        &:first-child {
            flex: 1;
        }
    }
`;

const ReferenceOriginStyled = styled(SmallText)`
    display: block;

    color: ${({ theme }) => theme.palette.grey[700]};
`;

const DateStyled = styled(SmallText)`
    display: block;

    color: ${({ theme }) => theme.palette.grey[500]};
`;

const HappyToBeContactedStyled = styled.button`
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: ${({ theme }) => theme.spacing[0.5]};
    padding-left: ${({ theme }) => theme.spacing[1.5]};
    padding-bottom: ${({ theme }) => theme.spacing[0.5]};
    padding-right: ${({ theme }) => theme.spacing[1.5]};

    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-radius: 10px;

    > span {
        // Icon
        &:first-child {
            flex-shrink: 0;
            width: ${({ theme }) => theme.spacing[2]};
            height: ${({ theme }) => theme.spacing[2]};
        }

        // Text
        &:last-child {
            ${({ theme }) => theme.typography.smallText};
            color: ${({ theme }) => theme.palette.grey[500]};

            margin: 0;
            margin-left: ${({ theme }) => theme.spacing[1]};
        }
    }
`;

export {
    WrapperStyled,
    HeaderStyled,
    LookedAfterStyled,
    CharacteristicContainerStyled,
    CharacteristicStyled,
    FooterStyled,
    ReferenceOriginStyled,
    DateStyled,
    HappyToBeContactedStyled,
};
