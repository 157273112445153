import styled from 'styled-components';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import { IconChevronLeft } from 'components/Icon';

const ButtonStyled = styled(Button)`
    display: flex;
    justify-content: start;
    color: ${({ theme }) => theme.palette.link};
    font-size: ${({ theme }) => theme.spacing[2]};
    margin-top: 0;
    margin-bottom: 0;
`;

const LinkStyled = styled(Link).attrs({
    variant: Link.Variant.Button.FLAT,
})`
    display: flex;
    justify-content: start;
    color: ${({ theme }) => theme.palette.link};
    & svg {
        fill: ${({ theme }) => theme.palette.link};
    }
`;

const IconStyled = styled(IconChevronLeft)`
    margin-left: -${({ theme }) => theme.spacing[0.5]};
    margin-right: ${({ theme }) => theme.spacing[1]};
    & svg {
        fill: ${({ theme }) => theme.palette.link};
    }
`;

export { ButtonStyled, LinkStyled, IconStyled };
