import React from 'react';
import { track, Events } from 'utils/analytics';
import {
    LinkWrapperStyled,
    LinkStyled,
    DisabledStyled,
    SocialIconStyled,
} from './SitterSocialLink.style';

const SitterSocialLink = ({ t, icon, title, socialLink, viewerIsMember, isPreview }) => (
    <LinkWrapperStyled>
        {viewerIsMember ? (
            <LinkStyled
                href={socialLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                    isPreview
                        ? null
                        : track(Events.BUTTONCLICK.create({ description: 'profile social link' }))
                }
            >
                <SocialIconStyled as={icon} palette="accent" />
                {t(title)}
            </LinkStyled>
        ) : (
            <DisabledStyled>
                <SocialIconStyled as={icon} palette="accent" />
                {t(title)}
            </DisabledStyled>
        )}
    </LinkWrapperStyled>
);

export default SitterSocialLink;
