import React, { Component } from 'react';
import { toTitleCase } from 'utils/strings';
import Tag from 'components/Tag';
import { Button } from 'components/buttons';
import { DetailsSectionHeader } from 'components/DetailsPages';
import { TagContainerStyled, IconStyled, MoreButtonStyled } from './PreferredLocation.style';

class PreferredLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLocationListExpanded: false,
        };
    }

    sortCountries = (firstElement, secondElement) => {
        const firstCountryName = firstElement.countryName.toLowerCase();
        const secondCountryName = secondElement.countryName.toLowerCase();
        if (firstCountryName < secondCountryName) {
            return -1;
        }
        if (firstCountryName > secondCountryName) {
            return 1;
        }
        return 0;
    };

    expandList = () => {
        this.setState({
            isLocationListExpanded: true,
        });
    };

    render() {
        const { isLocationListExpanded } = this.state;
        const { translate, favouriteCountries } = this.props;
        const endPosition = isLocationListExpanded ? favouriteCountries.length : 6;

        return (
            <>
                <DetailsSectionHeader>
                    {translate('pages_profileDetail_preferredLocationHeading')}
                </DetailsSectionHeader>
                <TagContainerStyled>
                    {favouriteCountries
                        .sort(this.sortCountries)
                        .slice(0, endPosition)
                        .map((country) => (
                            <Tag key={country.countrySlug}>{toTitleCase(country.countryName)}</Tag>
                        ))}
                </TagContainerStyled>
                {!isLocationListExpanded && favouriteCountries.length > 6 ? (
                    <MoreButtonStyled
                        variant={Button.Variant.LINK}
                        onClick={this.expandList}
                        data-testid="preferredLocation_viewAllButton"
                    >
                        View all <IconStyled size="small" />
                    </MoreButtonStyled>
                ) : null}
            </>
        );
    }
}

export default PreferredLocation;
