import styled from 'styled-components';
import { Button } from 'components/buttons';
import Tags from 'components/Tags';
import { IconArrowRight } from 'components/Icon';

const TagContainerStyled = styled(Tags)``;

const MoreButtonStyled = styled(Button)`
    /* We want to remove the button padding so the content aligns with other text */
    padding-left: 0;
`;

const IconStyled = styled(IconArrowRight)`
    margin-left: ${({ theme }) => theme.spacing[1]};
    & svg {
        fill: ${({ theme }) => theme.palette.link};
    }
`;

export { TagContainerStyled, MoreButtonStyled, IconStyled };
