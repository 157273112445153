import styled from 'styled-components';
import { smallTextStyles } from 'typography';

const LinkWrapperStyled = styled.div`
    ${smallTextStyles};
    display: flex;
    margin-top: 0;
    margin-bottom: ${({ theme }) => `${theme.spacing['0.5']}`};

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const LinkStyled = styled.a`
    display: flex;
    color: ${({ theme }) => theme.palette.accent};
    text-decoration: underline;

    :hover {
        text-decoration: underline;
    }
`;

const DisabledStyled = styled.div`
    display: flex;
    color: ${({ theme }) => theme.palette.textLight};
    text-decoration: none;
`;

const SocialIconStyled = styled.span`
    margin-right: ${({ theme }) => `${theme.spacing[2]}`};
    align-self: center;
`;

export { LinkWrapperStyled, LinkStyled, DisabledStyled, SocialIconStyled };
