/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Datepicker from 'components/Datepicker';
import { CalendarWrapperStyled } from 'components/Datepicker/components/Calendar';

const DatepickerStyled = styled(Datepicker)`
    margin: 0 auto;

    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /*
    * I don't like this but
    * there's not a much nicer way
    * to style this correctly right now
    */
    ${CalendarWrapperStyled} {
        margin-bottom: 0;
        margin-right: 0;
        &:last-of-type {
            display: none;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        flex-direction: row;
        align-items: flex-start;
        ${CalendarWrapperStyled} {
            margin-right: ${({ theme }) => theme.spacing[2]};
            &:last-of-type {
                margin-right: 0;
                display: flex;
            }
        }
    }
`;

export { DatepickerStyled };
