/* eslint-disable import/prefer-default-export */

const CALENDAR_STATE = {
    Active: 'active',
    Inactive: 'inactive',
};

const PROFILE_DETAIL_TESTSID = {
    calendar: 'profile-calendar-testid',
};

const VALUE_REVIEWS_SCROLL_OFFSET = -80;

export { CALENDAR_STATE, PROFILE_DETAIL_TESTSID, VALUE_REVIEWS_SCROLL_OFFSET };
