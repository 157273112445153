/* eslint-disable no-continue */
import { format, subDays, addDays } from 'date-fns';
import { parseDate } from 'api/helpers/format/date';
import { CALENDAR_STATE } from './ProfileDetailPage.constants';

const dayISO = (date = new Date()) => format(parseDate(date), 'yyyy-MM-dd');

const previousDayISO = (date) => dayISO(subDays(parseDate(date), 1));

const nextDayISO = (date) => dayISO(addDays(parseDate(date), 1));

/**
 * `generateAvailabilityCalendarDates` is a direct copy/paste from `app/app/helpers/sitter.js` - please keep in sync!
 *
 * When copy/pasting you need to:
 * - Replace `moment().format('yyyy-MM-dd')` with `dayISO()`
 * - Replace `moment(whatever).format('yyyy-MM-dd')` with `dayISO(whatever)`
 * - Replace `moment(whatever).subtract(1, 'day').format('yyyy-MM-dd');` with `previousDayISO(whatever)`
 * - Replace `moment(whatever).add(1, 'day').format('yyyy-MM-dd');` with `nextDayISO(whatever)`
 *
 * (keep an eye on `generateAvailabilityCalendarDatesNew` too because that is supposed to be the way we
 * will do it in the future)
 */

/**
 * Generates dates array for availability calendar chronologically ordered by start date and grouped by state
 */
export const generateAvailabilityCalendarDates = ({
    availableDates = [],
    unavailableDates = [],
}) => {
    // Set today
    const today = dayISO();

    const dateRanges = [];

    if (availableDates && availableDates.length > 0) {
        // Add available dates (availabilities) to the list
        availableDates.forEach((date) => {
            dateRanges.push({
                startDate: dayISO(date.startDate),
                endDate: dayISO(date.endDate),
                state: CALENDAR_STATE.Active,
            });
        });
    }

    if (unavailableDates && unavailableDates.length > 0) {
        // Add unavailable dates (current and upcoming assignments)
        unavailableDates.forEach((date) =>
            dateRanges.push({
                startDate: dayISO(date.startDate),
                endDate: dayISO(date.endDate),
                state: CALENDAR_STATE.Inactive,
            })
        );
    }

    // Chronologically sort dates by start date
    dateRanges.sort((dateA, dateB) => {
        if (dateA.startDate < dateB.startDate) return -1;
        if (dateA.startDate > dateB.startDate) return 1;
        return 0;
    });

    // Prioritise inactive dates over active ones
    let dateIdx = 0;
    while (dateIdx < dateRanges.length - 1) {
        const date = dateRanges[dateIdx];
        const nextDate = dateRanges[dateIdx + 1];

        // Do the two dates overlap?
        // Note: Two dates in the same state can never overlap
        if (date.endDate >= nextDate.startDate) {
            // Move nextDate startDate to after date endDate
            if (date.state === CALENDAR_STATE.Inactive) {
                nextDate.startDate = nextDayISO(date.endDate);

                // Next date is no longer valid, remove it from the list
                if (nextDate.startDate > nextDate.endDate) {
                    dateRanges.splice(dateIdx + 1, 1);
                }
            } else if (nextDate.state === CALENDAR_STATE.Inactive) {
                // Splits the date correctly when nextDate range falls inside it
                if (nextDate.endDate < date.endDate) {
                    dateRanges.splice(dateIdx + 2, 0, {
                        startDate: nextDayISO(nextDate.endDate),
                        endDate: date.endDate,
                        state: date.state,
                    });
                }

                date.endDate = previousDayISO(nextDate.startDate);

                // Date is no longer valid, remove it from the list and shift the index by 1
                if (date.startDate > date.endDate) {
                    dateRanges.splice(dateIdx, 1);
                    dateIdx -= 1;
                }
            }
        }

        // next?
        dateIdx += 1;
    }

    const dates = [];

    // Fill in the gaps with defaults
    for (dateIdx = 0; dateIdx < dateRanges.length; dateIdx += 1) {
        const date = dateRanges[dateIdx];

        // If the date is in the past relative to current date, then skip it.
        if (date.endDate < today) continue;
        else if (date.startDate < today) {
            dates.push({
                startDate: today,
                endDate: date.endDate,
                state: date.state,
            });
        } else {
            // Also add the date to the list
            dates.push({
                startDate: date.startDate,
                endDate: date.endDate,
                state: date.state,
            });
        }
    }

    // Group continuous dates with the same state
    dateIdx = 0;
    while (dateIdx < dates.length - 1) {
        const date = dates[dateIdx];

        // Not last element in the list?
        const nextDate = dates[dateIdx + 1];
        // If the states between current and next date are the same, we can group them together
        if (date.state === nextDate.state) {
            // Only group them if there is no gap in between
            const dateEndDateNextDay = nextDayISO(date.endDate);

            if (nextDate.startDate === dateEndDateNextDay) {
                date.endDate = nextDate.endDate;
                dates.splice(dateIdx + 1, 1);

                // Repeat the check on the same date
                dateIdx -= 1;
            }
        }

        // next?
        dateIdx += 1;
    }

    return dates;
};

/**
 * Take the profile's availableDates/unavailableDates and return a copy in the same
 * data structure that has been cleaned of overlaps
 *
 * We will use the same method as the app for consistency then convert the results in to the
 * format expected by our calendar components
 */
export const getAvailableDateRanges = ({ availableDates, unavailableDates }) => {
    // The app uses `generateAvailabilityCalendarDates` and passes the result straight to their calendar
    // Our calendar components expect this data as separate arrays so we will process the dates
    // then split them up again to maintain compatibility without a huge refactor
    const dateRanges = generateAvailabilityCalendarDates({
        availableDates,
        unavailableDates,
    });

    // The app uses `startDate`/`endDate` whereas we use `dateFrom`/`dateTo` so convert them
    const convertDates = ({ startDate, endDate }) => ({
        dateFrom: startDate,
        dateTo: endDate,
    });

    return {
        availableDates: dateRanges
            .filter(({ state }) => state === CALENDAR_STATE.Active)
            .map(convertDates),
        unavailableDates: dateRanges
            .filter(({ state }) => state === CALENDAR_STATE.Inactive)
            .map(convertDates),
    };
};
