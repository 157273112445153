import styled from 'styled-components';
import { Span } from 'typography';
import AnimalIcon from 'components/AnimalIcon';
import { Col } from 'components/layouts';
import { SIDEBAR_BREAKPOINT } from 'components/DetailsPages';

export const HeaderOptionsWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    /* 960 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

const InfoColStyled = styled(Col)`
    @media screen and (min-width: ${SIDEBAR_BREAKPOINT}px) {
        order: 1;
    }
`;

const IconStyled = styled(AnimalIcon)`
    margin-right: ${({ theme }) => theme.spacing[1]};
`;

const DarkIconStyled = styled(AnimalIcon)`
    margin-right: ${({ theme }) => theme.spacing[1]};

    & svg {
        fill: ${({ theme }) => theme.palette.grey[700]};
    }
`;

const Subheading = styled(Span)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-bottom: ${({ theme }) => theme.spacing[1]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        /* This constant is to properly align all the items to the right of the heading */
        min-width: 120px;
        margin-right: ${({ theme }) => theme.spacing[2]};
        margin-bottom: 0;
    }
`;

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    /* 1280 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        margin-top: 150px;
    }
`;

const ProfileDetailSection = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    &:last-of-type {
        margin-bottom: 0;
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallTablet}px) {
        display: flex;
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

export const FooterBreadcrumb = styled.div`
    padding-top: ${({ theme }) => theme.spacing[3]};
    padding-bottom: ${({ theme }) => theme.spacing[3]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        padding-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

export {
    Subheading,
    ProfileDetailSection,
    SpinnerContainer,
    InfoColStyled,
    IconStyled,
    DarkIconStyled,
};
