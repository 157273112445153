import React, { forwardRef } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils/cn';
import { TRANSLATION_KEY } from '../../constants';
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner';
import { FavouriteOutlineIcon, FavouriteFilledIcon } from '../../atoms/icons';

export type FavButtonProps = ComponentPropsWithoutRef<'button'> & {
    isFullwidth?: boolean;
    isFavourite: boolean;
    color?: 'default' | 'none';
    busy?: boolean;
};

export const colorVariants: Record<'default' | 'none', string> = {
    default: 'border border-solid border-grey-300 text-grey-500',
    none: '',
};

const FavouriteButton = forwardRef<HTMLButtonElement, FavButtonProps>(
    (
        {
            isFullwidth = false,
            isFavourite,
            color = 'default',
            className = null,
            busy = false,
            ...rest
        },
        ref
    ) => {
        const { t } = useTranslation(TRANSLATION_KEY);
        const classNames = cn(
            'flex w-auto cursor-pointer items-center justify-center rounded-md px-3 py-2 font-semibold',
            colorVariants[color],
            { 'w-full': isFullwidth },
            { 'w-32 cursor-wait': busy },
            className
        );

        const iconClass = cn('mr-2 h-4 w-4 text-grey-500', {
            'animate-[pulse_0.7s_ease-in-out]': isFavourite,
        });

        return (
            <button
                type="button"
                ref={ref}
                className={classNames}
                disabled={busy}
                {...rest}
            >
                {busy ? (
                    <LoadingSpinner size="small" className="ml-2" />
                ) : isFavourite ? (
                    <>
                        <FavouriteFilledIcon className={iconClass} />
                        {t('atom_favourite_button_favourited')}
                    </>
                ) : (
                    <>
                        <FavouriteOutlineIcon className={iconClass} />
                        {t('atom_favourite_button_favourite')}
                    </>
                )}
            </button>
        );
    }
);

export default FavouriteButton;
