import { format } from 'date-fns';

import { parseDate } from '../format/date';
import { getRatingAverage } from './ratings';

export const getLookedAfter = (item) => {
    if (item.hasLookedAfterPets && item.hasLookedAfterHouse) {
        return 'petsAndHome';
    }

    if (item.hasLookedAfterPets) {
        return 'pets';
    }

    if (item.hasLookedAfterHouse) {
        return 'home';
    }

    return undefined;
};

export const transformReference = (item, sitterName) => ({
    key: item.id || item.firstName + item.sentAt,
    ratingAverage: getRatingAverage(item),
    characteristics: item.characteristics,
    happyToBeContacted: item.happyToBeContacted,
    sitterName,
    refereeName: item.firstName,
    reference: item.reference,
    refereeConnection: item.refereeConnection || item.type,
    lookedAfter: getLookedAfter(item),
    date: format(parseDate(item.sentAt), 'dd MMMM yyyy')
});
