import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsableTagContainerStyled, CollapsableTagTextStyled } from './CollapsableTags.style';

const CollapsableTags = ({ count, children }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [shouldShowCollapseButton, setShouldShowCollapseButton] = useState(true);

    const toggleCollapsable = useCallback(() => setIsCollapsed((oldValue) => !oldValue), []);

    useEffect(() => {
        const { clientHeight, scrollHeight } = ref.current;

        setShouldShowCollapseButton(clientHeight !== scrollHeight);
    }, [children]);

    return (
        <>
            <CollapsableTagContainerStyled isStacked ref={ref} isCollapsed={isCollapsed}>
                {children}
            </CollapsableTagContainerStyled>
            {shouldShowCollapseButton ? (
                <CollapsableTagTextStyled onClick={toggleCollapsable}>
                    {isCollapsed
                        ? t('pages_profileDetail_petExperience_showAll', {
                              count,
                          })
                        : t('pages_profileDetail_petExperience_showLess')}
                </CollapsableTagTextStyled>
            ) : null}
        </>
    );
};

CollapsableTags.propTypes = {};

export default CollapsableTags;
