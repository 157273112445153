import { DetailsTagContainer } from 'components/DetailsPages';
import { Button } from 'components/buttons';
import styled from 'styled-components';

const MAX_HEIGHT = '132px'; // Hard coding this for the test but essentially this is 8rem + 4px margin for the tags

export const CollapsableTagContainerStyled = styled(DetailsTagContainer).attrs({
    isStacked: true,
})`
    max-height: ${({ isCollapsed }) => (isCollapsed ? MAX_HEIGHT : 'unset')};
    overflow: hidden;

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        max-height: unset;
    }
`;

export const CollapsableTagTextStyled = styled(Button).attrs({
    variant: Button.Variant.LINK,
})`
    ${({ theme }) => theme.typography.p};

    padding: 0;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing[1.5]};
    vertical-align: baseline;
    background: transparent;

    /* 800 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeTablet}px) {
        display: none;
    }
`;
